import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);

  const [isIosModalOpen, setIsIosModalOpen] = useState(false);

  const showIosModal = () => setIsIosModalOpen(true);
  const hideIosModal = () => setIsIosModalOpen(false);

  return (
    <ModalContext.Provider value={{ isModalOpen, showModal, hideModal, isIosModalOpen, showIosModal, hideIosModal }}>
      {children}
    </ModalContext.Provider>
  );
};
