import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export function useScroll() {
  return useContext(ScrollContext);
}

export const ScrollProvider = ({ children }) => {
  // 참조를 저장할 객체
  const [refs, setRefs] = useState({});

  // 참조를 추가하는 함수
  const addRef = (name, ref) => {
    // 이미 등록된 ref와 다를 경우에만 상태를 업데이트합니다.
    if (refs[name] !== ref) {
      setRefs((prevRefs) => ({
        ...prevRefs,
        [name]: ref
      }));
    }
  };


  return (
    <ScrollContext.Provider value={{ refs, addRef }}>
      {children}
    </ScrollContext.Provider>
  );
};
