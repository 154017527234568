// ContactForm.js

import React, { useState } from 'react';
import './ContactForm.css'

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Firebase 함수 URL을 사용하여 요청 보내기
    const sendEmailFunctionURL = 'https://us-central1-littlebuffett-official-website.cloudfunctions.net/sendEmail';
    try {
      const response = await fetch(sendEmailFunctionURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, message }),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setEmail('');
        setMessage('');
        // 성공 메시지 표시 또는 폼 초기화
      } else {
        console.error('Failed to send email');
        // 에러 메시지 표시
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="inquiry-container">
      <div className="inquiry-title">서비스 관련 문의를 남겨주세요.</div>
      <form className='form-container' onSubmit={handleSubmit}>
        <div className='send-container'>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='연락받을 이메일을 입력해주세요.'
            required
          />
          <button className='send-button' type="submit">문의하기</button>
        </div>
        <div>
          <textarea
            id="message"
            value={message}
            placeholder='내용을 입력해주세요.'
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>

      </form>
    </div>
  );
};

export default ContactForm;
