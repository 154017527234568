import React, { useRef, useState } from 'react';
import './Header.css';
import { useScroll } from './ScrollContext';
import { useModal } from './ModalContext';




export default function Header() {
  const { refs } = useScroll();
  const { showModal: originalShowModal } = useModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // 메뉴 상태 관리
  const [menuImage, setMenuImage] = useState('./images/hamburger.png');



  const scrollToSection = (sectionName) => {
    const sectionRef = refs[sectionName];
    if (sectionRef?.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // 메뉴 상태에 따라 이미지를 햄버거 또는 X 이미지로 변경합니다.
    if (!isMenuOpen) {
      setMenuImage('./images/close.png'); // 메뉴가 열리면 X 이미지로 변경
    } else {
      setMenuImage('./images/hamburger.png'); // 메뉴가 닫히면 햄버거 이미지로 변경
    }
  };

  // 문의하기 클릭 시 실행될 함수
  const showModal = () => {
    toggleMenu(); // 메뉴 상태 토글
    originalShowModal(); // 원래 모달 보여주는 함수 실행
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-container">
          <img src="/logo.png" alt="Logo" className="logo" />
        </div>
        <img className='hamburger' src={menuImage} alt="hamburger menu" onClick={toggleMenu} />
        <nav className="navbar">
          <ul className="nav-items">
            <li className='nav-list' onClick={() => scrollToSection('section1')}>회사 소개</li>
            <li className='nav-list' onClick={() => scrollToSection('section2')}>서비스 소개</li>
            <li className='nav-list' onClick={() => scrollToSection('section3')}>회사 연혁</li>
            <li className='nav-list' onClick={() => scrollToSection('section4')}>회사 소식</li>
            <li className='nav-list' onClick={() => scrollToSection('section5')}>뉴스레터</li>
            <li className='nav-list' onClick={(event) => {
              event.stopPropagation();
              originalShowModal();
            }}>문의하기</li>
          </ul>
        </nav>
      </div>
      <div className={`header-menu ${isMenuOpen ? 'open' : 'closed'}`}>
        <ul className="nav-items">
          <li className='nav-list' onClick={() => scrollToSection('section1')}>회사 소개</li>
          <li className='nav-list' onClick={() => scrollToSection('section2')}>서비스 소개</li>
          <li className='nav-list' onClick={() => scrollToSection('section3')}>회사 연혁</li>
          <li className='nav-list' onClick={() => scrollToSection('section4')}>회사 소식</li>
          <li className='nav-list' onClick={() => scrollToSection('section5')}>뉴스레터</li>
          <li className='nav-list' onClick={showModal}>문의하기</li>
        </ul>
      </div>
    </header>
  )
}
