import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';


export default function Footer() {
  return (
    <footer className='footer'>
      <div className="footer-section">
        <img className='footer-logo' src="./images/fractalfn_logo.png" alt="brand logo" />
        <div className='footer-container'>
          <div className="footer-text footer-bold">주식회사 프랙탈에프엔</div>
          <div className="footer-text">대표: 문명덕 |        사업자등록번호: 216-88-02237</div>
          <div className="footer-text">주소: 서울특별시 중구 세종대로 136 서울파이낸스센터 3층 | 연구소: 서울특별시 마포구 마포대로 78 제2서울핀테크랩 8층</div>
          <div className="footer-text">이메일: info@fractalfn.com | Copyright 2021. 주식회사 프랙탈에프엔 all rights reserved.</div>
          <div className='link-info'>
            <div className="link-item">
              <Link to="/terms-of-condition">이용약관</Link>
            </div>
            <div className="link-item">
              <Link to="/information-handling">개인정보처리방침</Link>
            </div>
          </div>
        </div>
        <div className="footer-sns-icons">
          <a href="https://www.instagram.com/littlebuffett.kr/" target="_blank" rel="noopener noreferrer" className="instagram">
            <img className='footer-icon-image' src="./images/instagram-icon.png" alt="instagram" />
          </a>
          <a href="https://blog.naver.com/little_buffett" target="_blank" rel="noopener noreferrer" className="naver-blog">
            <img className='footer-icon-image' src="./images/naver-blog-icon.png" alt="naver-blog" />
          </a>
        </div>
      </div>
    </footer>
  )
}
