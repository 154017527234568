import React, { useState, useEffect } from 'react'
import './InterviewPage.css';
import { Link } from 'react-router-dom';


function InterviewPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mobileBreakpoint = 1140; // 모바일 화면 너비 기준

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageSrc = windowWidth <= mobileBreakpoint ? "./images/interview-image.png" : "./images/interview-image.png";

  return (
    <div className="event-page">
      {windowWidth <= mobileBreakpoint && (<div className="sticky-header">
        <img src="/logo.png" alt="Logo" className="logo" />
        <a href="https://digitallink.kr/download" target="_blank" rel="noopener noreferrer" className="app-download-link">
          앱 다운로드
        </a>
      </div>)}
      <div className='back-btn-container'>
        <Link className='link-close-btn' to='/'>X</Link>
        <Link className='link-back-btn' to='/event-page'>&lt;</Link>
      </div>
      <img className='event-image' src={imageSrc} alt="event details" />
    </div>
  );
}

export default InterviewPage