import React, { useState, useEffect } from 'react';
import './TermsOfCondition.css';

export default function InformationHandling() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1140);
  const [selectedVersion, setSelectedVersion] = useState('current');

  const versions = [
    { value: 'current', label: '2024년 8월 1일' },
    { value: 'previous', label: '2022년 8월 1일' },
    // 추후 더 많은 버전을 여기에 추가할 수 있습니다.
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1140);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderSection = (title, content) => {
    return (
      <div className="section">
        <h2 className={`section-title ${isMobile ? 'mobile' : ''}`}>{title}</h2>
        <div className={`section-content ${isMobile ? 'mobile' : ''}`}>
          {content}
        </div>
      </div>
    );
  };

  const currentVersion = (
    <>
      {renderSection('개인정보 처리방침',
        <p>주식회사 프랙탈에프엔(이하 "회사")는 『개인정보 보호법』에 따라 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. 개인정보 처리방침을 변경하는 경우에는 변경에 대한 사실 및 시행일, 변경 전후의 내용을 홈페이지 공지사항을 통해 지속적으로 공개합니다.</p>
      )}
      {renderSection('제1조 (수집하는 개인정보 항목)',
        <p>회사에서 수집하는 개인정보 항목은 다음과 같습니다.
          <br />
          <br />① 회원가입 및 리틀버핏 서비스 이용시 필요한 개인정보 항목
          <br />
          <br /> 1. 서비스 이용에 따른 본인확인 및 개인 식별
          <br />2. 불량회원의 부정이용 방지와 비인가 사용 방지 및 중복가입방지
          <br />3. 가입의사 확인, 가입 및 가입횟수 제한
          <br />4. 회원상담 진행, 회원불만 접수 및 처리, 분쟁조정을 위한 기록보존
          <br />5. 공지사항 전달
          <br />6. 신규 서비스(제품) 개발 및 특화
          <br />7. 인구통계학적 특성에 따른 서비스 제공 및 광고 게재
          <br />8. 접속 빈도 파악
          <br />9. 서비스의 이용실적 분석
          <br /> 10. 회원의 서비스 이용에 대한 통계
          <br />11. 이벤트 등 광고성 정보 전달
          <br /> 12. 새로운 서비스 및 상품 안내
          <br />
          <br />② 원칙적으로 회사는 회원의 개인정보를 수집 및 이용목적에 한해서만 이용하며 타인 또는 타기업, 기관에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          <br />
          <br />1. 정보주체로부터 별도의 동의를 받은 경우
          <br />2. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
          <br />3. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
          <br />4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
          <br />5. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
          <br />6. 업무상 연락을 위하여 회원의 정보(이름, 주소, 휴대전화, 전화번호)를 사용하는 경우</p>
      )}
      {renderSection('제2조 (개인정보 수집 항목 및 목적)',
        <p>리틀버핏은 서비스 이용에 필요한 최소한의 개인정보를 수집합니다. 이용자의 개인정보와 서비스 방문 및 검색, 서비스 이용을 통해 이용자의 관심, 흥미, 기호 등을 분석하여 개인 성향에 맞춤화된 서비스를 제공합니다.

          <br />① 필수
          <br />
          <br />1. 회원 가입 및 회원 식별, 회원관리
          <br />
          <br />가. 아이디, 이메일, 이름, 휴대폰번호, 비밀번호, 서비스 이용기록, 성별, CI
          <br />
          <br />1. 부정행위 방지
          <br />
          <br />가. 부정행위 탐지된 아이디, 이름, 휴대펀본허, 부정행위 기록, CI
          <br />
          <br />② 선택
          <br />
          <br />1. 포트폴리오 서비스 제공을 위한 서비스
          <br />
          <br />가. 증권사 계좌번호, 주식 종목, 평가금액 등
          <br />
          <br />1. 서비스의 마케팅 및 프로모션
          <br />
          <br />가. 아이디, 휴대폰번호, 이메일, 이름
          <br />
          <br />1. 대회 상금 지급
          <br />
          <br /> 가. 계좌번호, 식별정보

          <br />③ 추가적인 개인 정보 수집
          <br />
          <br />1. 상담 및 민원사항
          <br />
          <br />가. 아이디, 이름, 이메일, 연락처
          <br />
          <br />④ 기타 수집항목
          <br />
          <br />1. 서비스 향상
          <br />
          <br />가. 서비스 이용기록, 접속 로그, 쿠키, 접속 IP정보, 결제 기록</p>
      )}
      {renderSection('제3조 (개인정보의 보유 및 이용기간)',
        <p>① 회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보의 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리합니다.

          <br />② 개인정보 보유 및 이용기간은 다음과 같습니다.
          <br />
          <br />1. 회원 가입 및 관리 목적으로 수집된 개인정보는 정보주체의 회원 가입일로부터 회원 탈퇴일까지 보유·이용됩니다. 단, 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지 보유 및 이용합니다.
          <br />2. 포트폴리오 서비스 제공을 위한 서비스 및 서비스의 마케팅 및 프로모션 등과 관련한 개인정보는 수집·이용에 관한 동의일로부터 동의 철회시까지 보유·이용됩니다. 단, 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지 보유 및 이용합니다.
          <br />3. 소비자의 불만 또는 분쟁처리에 관한 기록은 접수된 이후 3년까지 보유합니다.
          <br />4. 표시·광고에 관한 기록은 『전자상거래 등에서의 소비자보호에 관한 법률』에 따라 6개월까지 보유합니다.
          <br />5. 웹사이트, 앱 방문 기록은 『통신비밀보호법』에 따라 3개월까지 보유합니다.
          <br /> 6. 제세공과금 대납을 위한 정보는 『국세기본법』에 따라 5년까지 보유합니다.
          <br /> 7. 대금결제 및 재화 등에 공급에 관한 기록은 『전자상거래 등에서의 소비자보호에 관한 법률』에 따라 5년까지 보유합니다.</p>
      )}
      {renderSection('제4조 (개인정보처리의 위탁)',
        <p>① 회사는 서비스 제공에 있어 필요한 업무 중 일부를 외부 업체가 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가 관계 법령을 위반하지 않도록 관리·감독하고 있습니다.

          <br />② 회사가 개인정보처리를 위탁한 업체는 다음과 같습니다.
          <br />
          <br />1. Amazon Web Service: 데이터보관
          <br /> 2. NICE평가정보(주): 본인인증
          <br />3. (주)헥토데이터: 마이데이터
          <br />
          <br />③ 리틀버핏은 원활한 서비스 제공을 위하여 개인정보 처리업무를 위탁하고 있습니다. 리틀버핏은 처리업무 위탁 시 『개인정보 보호법』 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상책임 등에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 관리·감독하고 있습니다.</p>
      )}
      {renderSection('제5조 (회원과 법정대리인의 권리·의무 및 행사방법)',
        <p>① 회원의 개인정보는 열람, 정정, 삭제, 동의 철회, 처리정지 요청을 할 수 있습니다. 서비스를 통해 직접 확인하지 못하는 정보는 리틀버핏에게 요청하여 확인할 수 있습니다. 법정대리인이나 위임을 받은 대리인은 위임장, 가족관계증명서 등을 제출하여 정당한 대리인 여부를 입증해야 합니다. 다만, 아래의 경우에는 동의 철회, 삭제, 처리정지가 어려울 수 있습니다.
          <br />
          <br />1. 법령에 특별한 규정이 있거나 법령상 의무를 다하기 위해 불가피한 경우
          <br />2. 다른 사람의 신체, 생명을 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
          <br />3. 개인정보를 처리하지 않으면서 회원과 약정한 서비스를 제공하지 못하는 경우로서 회원이 그 계약의 해지 의사를 명확하게 밝히지 않은 경우
          <br />
          <br />② 아이디 및 비밀번호는 타인에게 양도하거나 대여할 수 없으며 이로 인해 발생한 문제에 대해서는 회사가 책임지지 않습니다. 타인의 정보 및 존엄성을 훼손할 시에는『개인정보 보호법』, 『정보통신망법』 등에 의해 처벌받을 수 있습니다.

          <br />③ 회사는 이용자 이용 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
      )}
      {renderSection('제6조 (개인정보의 파기, 파기절차 및 파기방법)',
        <p>① 회사는 개인정보의 보유기간이 경과된 경우에는 다음 각 호의 사유가 없는 한 보유기간의 종료일로부터 10영업일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 다음 각 호의 사유가 없는 한 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 10영업일 이내에 그 개인정보를 파기합니다.
          <br />
          <br />1. 신용정보회사 등이 민ㆍ형사상의 책임 또는 시효가 지속되거나 분쟁의 입증자료로서 개인신용정보를 보유하는 경우
          <br />2. 법령에 따라 보존하여야 하는 경우
          <br />3. 기타 이와 유사한 정당한 사유가 있는 경우
          <br />
          <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
          <br />
          <br />③ 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>
      )}
      {renderSection('제7조 (개인정보의 기술적·관리적 보호 대책)',
        <p>회사는 정보주체의 안전성 확보 조치를 위하여 다음 각 호와 같이 조치를 하고 있습니다.
          <br />
          <br />① 개인정보에 대한 접근 통제 및 접근권한의 제한 조치

          <br />② 개인정보를 안전하게 저장ㆍ전송할 수 있는 암호화 기술의 적용 또는 이에 상응하는 조치

          <br />③ 개인정보 침해사고 발생에 대응하기 위한 접속기록의 보관 및 위조ㆍ변조 방지를 위한 조치

          <br />④ 개인정보에 대한 보안프로그램의 설치 및 갱신

          <br />⑤ 개인정보의 안전한 보관을 위한 보관시설의 마련 또는 잠금 장치의 설치 등

          <br />⑥ 개인정보 취급 직원의 최소화 및 교육실시</p>
      )}
      {renderSection('제8조 (개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항)',
        <p>쿠키에 의한 개인정보 수집

          <br />회사의 모든 사이트는 귀하에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(인터넷 익스플로러, 넷스케이프 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 회사 사이트의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.</p>
      )}
      {renderSection('제9조 (개인정보보호책임자와 민원처리부서)',
        <p>① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.
          <br />
          <br />이름 : 문명덕

          <br />전화번호: 0507-1406-5488

          <br />이메일: info@fractalfn.com

          <br />(근무시간: 평일 09:00~17:00, 점심시간 13:00~14:00, 주말 및 공휴일 휴무)
          <br />
          <br />② 개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에 문의하실 수 있습니다.
          <br />
          <br />1. 개인정보 침해신고센터: (국번 없이) 118 (privacy.kisa.or.kr)
          <br />2. 개인정보 분쟁조정위원회: (국번 없이) 1833-6972 (www.kopico.go.kr)
          <br />3. 대검찰청: (국번없이) 1301 (www.spo.go.kr)
          <br />4. 경찰청: (국번 없이) 182 (ecrm.cyber.go.kr)
          <br /> <br />&lt;부칙&gt;

          <br />본 약관은 2024년 8월 1일부터 적용됩니다.
        </p>
      )}

    </>);

  const previousVersion = (
    <>
      {renderSection('리틀버핏 개인정보 처리방침', <p>주식회사 프랙탈에프엔(이하 ‘회사’)는 이용자의 개인정보 보호를 최우선으로 생각하며, 이용자가 회사의 서비스를 이용하기 위해 회사에 제공한 개인정보 보호에 최선을 다하고 있습니다. 이에 회사는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 "개인정보 보호법" 등 개인정보 보호와 관련된 법령을 준수하고 있습니다.
        <br />회사는 본 개인정보처리방침을 사이트에 공개함으로써 이용자가 언제든지 쉽게 확인할 수 있도록 하고 있습니다.
        <br />본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며, 개정 시 버전 관리를 통하여 개정사항을 쉽게 확인할 수 있도록 하고 있습니다.</p>)}
      {renderSection('제1조(수집하는 개인정보 항목)', <p>회사에서 수집하는 개인정보 항목은 다음과 같습니다.
        <br />회원가입 및 리틀버핏 서비스 이용시 필요한 개인정보 항목
        <br />회원관리
        <br />서비스 이용에 따른 본인확인 및 개인 식별
        <br />불량회원의 부정이용 방지와 비인가 사용 방지 및 중복가입방지
        <br />가입의사 확인, 가입 및 가입횟수 제한
        <br />회원상담 진행, 회원불만 접수 및 처리, 분쟁조정을 위한 기록보존
        <br />공지사항 전달
        <br />신규 서비스 개발 및 마케팅, 광고
        <br />신규 서비스(제품) 개발 및 특화
        <br />인구통계학적 특성에 따른 서비스 제공 및 광고 게재
        <br /> 접속 빈도 파악
        <br />서비스의 이용실적 분석
        <br /> 회원의 서비스 이용에 대한 통계
        <br />이벤트 등 광고성 정보 전달
        <br />새로운 서비스 및 상품 안내
        <br />원칙적으로 회사는 회원의 개인정보를 수집 및 이용목적에 한해서만 이용하며 타인 또는 타기업/기관에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        <br />① 정보주체로부터 별도의 동의를 받은 경우
        <br />② 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
        <br />③ 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
        <br /> ④ 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
        <br />⑤ 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
        <br />⑥ 업무상 연락을 위하여 회원의 정보(이름, 주소, 휴대전화, 전화번호)를 사용하는 경우</p>)}
      {renderSection('제2조(개인정보의 처리목적)', <p>회사는 위 제1항과 같은 ‘개인정보의 수집 및 이용목적’을 위하여 아래와 같은 원칙에 의하여 개인정보를 수집하고 있습니다.
        <br />수집 개인정보 항목
        <br />해당 서비스의 본질적 기능을 수행하기 위한 정보는 필수정보로서 수집하며 회원이 그 정보를 회사에 제공하지 않는 경우 서비스 이용에 제한이 가해질 수 있지만, 선택정보 즉, 보다 특화된 서비스를 제공하기 위해 추가 수집되는 정보의 경우에는 이를 입력하지 않은 경우에도 서비스 이용제한은 없습니다.
        서비스 이용에 따라 다음과 같은 정보를 수집합니다.
        <br />① 회원 가입 시 수집항목
        이메일, 휴대폰번호, 이름, 닉네임
        <br />② 증권사와 연동된 포트폴리오 서비스 제공을 위해 계좌 번호 정보를 수집합니다. 또한 포트폴리오 서비스 제공을 위해 보유 증권의 수량 및 평균단가, 계좌 순자산 정보를 수집합니다.
        <br />③ 회사는 공동인증서 등의 개인 식별키를 일체 서버에 저장하지 않으며, 회원의 기기 내에서만 암호화하여 안전하게 관리됩니다.
        <br />④ 그 외 부가서비스 서비스 신청 시 수집항목
        부가서비스 신청 과정에서 개인정보 중 일부 항목을 추가 수집할 수 있으며, 수집항목은 각 부가서비스 신청 페이지 개인정보 수집 및 이용 동의 영역의 별도 항목으로 대체합니다.
        <br />⑤ 상담 및 민원사항 처리 시
        로그인 아이디, 이름, 이메일, 연락처, 개별 서비스 계정 아이디 및 비밀번호(테스트, 계정 연동 필요 시), 사업자등록증 등 증빙서류, 계좌정보(거래은행명, 계좌번호, 예금주 성명)
        기타 수집항목 : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP정보, 결제 기록
        개인정보 수집 방법
        회사는 다음과 같은 방법으로 개인정보를 수집합니다.
        홈페이지 회원 가입 시점
        홈페이지 내 부가서비스 별 페이지
        전화, 채팅, 이메일을 통한 회원상담 및 서비스 이용관리
        증권사 연동 기능 사용시</p>)}
      {renderSection('제3조(개인정보의 보유 및 이용기간)', <p>
        <br />① 회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보의 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리합니다.
        <br />② 각각의 개인정보 보유 및 이용기간은 다음과 같습니다.
        <br />리틀버핏 서비스 사용시 수집된 개인정보의 보유 및 이용기간
        <br />회원가입
        <br />보유사유: 회원 관리, 이용자 상담 및 민원처리
        <br />이용기간: 회원탈퇴 시
        <br />다만, 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지 보유 및 이용합니다.
        <br />재화 또는 서비스 제공에 따른 법률에 의한 보존
        <br />소비자의 불만 또는 분쟁처리에 관한 기록
        <br />이용기간: 3년
        <br />표시·광고에 관한 기록
        <br />이용기간: 6개월
        <br />로그인 기록
        <br />관련법률: 통신비밀보호법
        <br />이용기간: 3개월
        <br />이용기간: 5년
        <br />③ 개인정보의 보유 및 이용기간은 서비스 이용계약 체결 시(회원가입)부터 서비스 이용계약 해지(탈퇴신청)까지 입니다. 회사는 다른 법령에서 별도의 기간을 정하고 있거나 이용자의 요청이 있는 경우를 제외하면, 법령에서 정의하는 기간(1년) 동안 재이용하지 아니하는 이용자의 개인정보를 파기하거나 다른 이용자의 개인정보와 분리하여 별도로 저장·관리합니다. 단, 기간 만료 30일 이전까지 개인정보가 파기되거나 분리되어 저장·관리되는 사실과 기간 만료일 및 해당 개인정보의 항목을 전자우편·서면·FAX·전화 또는 이와 유사한 방법 중 어느 하나의 방법으로 이용자에게 알립니다.
      </p>)}
      {renderSection('제4조(개인정보처리의 위탁)', <p>
        ① 회사는 서비스 제공에 있어 필요한 업무 중 일부를 외부 업체가 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가 관계 법령을 위반하지 않도록 관리·감독하고 있습니다.
        <br />② 회사가 개인정보처리를 위탁한 업체는 다음과 같습니다.
        <br />(주)코드에프
        <br />"회사"는 서비스 이행을 위해 아래와 같이 개인정보 취급 업무를 외부 전문업체에 위탁하여 운영하고 있으며, 관련 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.

        <br />4. 국가•일시•방법
        <br />국가 : 대한민국
        <br />일시 : 증권사 연동 기능 사용시
        <br />방법 : 암호화를 통한 보안된 통신 이용
      </p>)}
      {renderSection('제5조(이용자 및 법정대리인의 권리ㆍ의무와 행사방법)', <p>① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
        다만, 개인정보 보호법 제35조 제4항, 제36조 제1항, 제37조 제2항 등 관계 법령에서 정하는 바에 따라 이용자의 개인정보 열람·정정·삭제·처리정지 요구 등의 권리 행사가 제한될 수 있습니다.
        <br />② 이용자의 권리 행사는 개인정보 보호법 시행령 제41조 제1항에 따라서 서면, 전자우편, FAX 등을 통하여서 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다
        <br />③ 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여서 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
        <br />④ 개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        <br />⑤ 회사는 이용자 이용 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>)}
      {renderSection('제6조(개인정보의 파기)', <p>① 회사는 개인정보 보유 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때는 지체없이 해당 개인정보를 파기합니다.
        <br />② 이용자로부터 동의받은 개인정보 보유 기간이 지나거나 처리목적이 달성되었음에도 불구하고 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        <br />③ 개인정보 파기 방법은 다음과 같습니다.
        전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 영구 삭제
        종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각</p>)}
      {renderSection('제7조(개인정보의 기술적·관리적 보호 대책)', <p>
        회사는 이용자들의 개인정보를 처리하면서 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.
        <br />내부관리계획 수립
        <br />회사는 회사가 처리하는 개인정보의 안전한 관리를 위하여 내부관리계획을 수립하여 시행하고 있습니다.
        <br />이용자의 개인정보 암호화
        <br />회사는 이용자의 비밀번호 등의 개인정보를 안전한 암호알고리즘에 의해 암호화하여 저장 및 관리하고 있습니다.
        <br />해킹 등에 대비한 대책
        <br />회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
        <br />개인정보취급자의 최소화 및 교육
        <br />회사는 개인정보 취급자를 업무 수행에 필요한 최소한으로 제한하며, 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 개인정보보호의 중요성을 인식시키고 있습니다.
      </p>)}
      {renderSection('제8조(개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항)', <p>① 회사는 이용자의 서비스 편의를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
        <br />② 쿠키는 웹사이트가 고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의 정보입니다.
        <br />쿠키의 사용 목적
        <br />쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를 통해 이용자는 더욱 손쉽게 서비스를 이용할 수 있게 됩니다.
        <br />쿠키의 설치∙운영 및 거부
        <br />이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
        <br />쿠키 설정 거부 방법
        <br />이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
        <br />Chrome : 설정 메뉴 선택 &gt; 고급 설정 표시 선택  개인정보 및 보안 &gt; 콘텐츠 설정 선택 &gt; 쿠키 수준 설정
        <br />Safari : 환경설정 메뉴 선택 &gt; 개인정보 탭 선택 &gt; 쿠키 및 웹 사이트 데이터 수준 설정</p>)}
      {renderSection('제9조(개인정보 보호 책임자 및 담당 부서)', <p>① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.
        <br />개인정보 보호 책임자
        <br />이름 : 문명덕
        <br />직책: 개인정보 보호 책임자
        <br />전화번호: 010-8470-5488
        <br />이메일: mdmoon@fractalfn.com
        <br />담당 부서: 정보보안팀
        <br /> ② 이용자가 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원은 개인정보 보호 책임자 및 담당 부서로 문의하실 수 있습니다. 회사는 이용자의 문의 사항에 대해 답변 및 처리해드릴 것입니다.</p>)}
      {renderSection('제11조(권익침해 구제방법)', <p>
        개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에 문의하실 수 있습니다.
        <br />개인정보 침해신고센터 (한국인터넷진흥원 운영)
        <br />홈페이지: privacy.kisa.or.kr
        <br />전화: (국번 없이) 118
        <br />개인정보 분쟁조정위원회
        <br />홈페이지: www.kopico.go.kr
        <br />전화: (국번 없이) 1833-6972
        <br />대검찰청 사이버범죄수사단
        <br />홈페이지: www.spo.go.kr
        <br />전화 : 02-3480-3573
        <br />경찰청 사이버안전국
        <br />홈페이지 : cyberbureau.police.go.kr
        <br /> 전화 : (국번 없이) 182</p>)}
      {renderSection('제12조(링크 사이트에 대한 책임)', <p>
        회사는 이용자에게 다른 외부사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트에 대한 통제권이 없으므로 이용자가 외부사이트로부터 제공받는 서비스나 자료의 유용성, 진실성, 적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부사이트의 개인정보처리방침은 회사와 무관하므로 해당 외부사이트의 정책을 확인하시기 바랍니다.
      </p>)}
      {renderSection('제13조(개인정보처리방침 변경)', <p>현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 이전부터 '공지사항'을 통해 알릴 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 때는 최소 30일 이전에 알립니다.
        <br />
        <br />부칙
        <br />본 개인정보처리방침은 2022년 08월 01일부터 적용됩니다.</p>)}

    </>
  );

  const getVersionContent = (version) => {
    switch (version) {
      case 'current':
        return currentVersion;
      case 'previous':
        return previousVersion;
      default:
        return <p>선택된 버전의 내용을 찾을 수 없습니다.</p>;
    }
  };


  return (
    <div className={`terms-of-service ${isMobile ? 'mobile' : ''}`}>
      <h1 className="main-title">개인정보 처리방침</h1>

      <div className="version-selector">
        <select
          value={selectedVersion}
          onChange={(e) => setSelectedVersion(e.target.value)}
          className="version-dropdown"
        >
          {versions.map((version) => (
            <option key={version.value} value={version.value}>
              {version.label}
            </option>
          ))}
        </select>
      </div>

      {getVersionContent(selectedVersion)}
    </div>
  );
}



