import React, { useState, useEffect } from 'react'
import './EventPage.css';
import { Link } from 'react-router-dom';


function EventPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mobileBreakpoint = 1140; // 모바일 화면 너비 기준

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageSrc = windowWidth <= mobileBreakpoint ? "./images/mobile-event-image.png" : "./images/event-image.png";

  return (
    <div className="event-page">
      {windowWidth <= mobileBreakpoint && (<div className="sticky-header">
        <img src="/logo.png" alt="Logo" className="logo" />
        <a href="https://digitallink.kr/download" target="_blank" rel="noopener noreferrer" className="app-download-link">
          앱 다운로드
        </a>
      </div>)}
      <div className='back-btn-container'>
        <Link className='link-back-btn' to='/'>
          <button className='back-btn'>X</button>
        </Link>
      </div>
      <div className='competition-btn'>
        <Link className='link-competition-awards' to='/awards-page'>
          대회 시상식 보러가기
        </Link>
        <Link className='link-winner-interview' to='/interview-page'>
          우승자 인터뷰 보러가기
        </Link>

      </div>
      <img className='event-image' src={imageSrc} alt="event details" />
    </div>
  );
}

export default EventPage