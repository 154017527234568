// SubscriptionForm.js
import React, { useEffect } from 'react';

const SubscriptionForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://s3.ap-northeast-2.amazonaws.com/resource.stibee.com/subscribe/stb_subscribe_form.js";
    script.async = true;

    document.body.appendChild(script);

    // 스크립트가 언마운트될 때 제거
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div id="stb_subscribe">
      <form action="https://stibee.com/api/v1.0/lists/u9_DUl5gRPsc-rBxe17pN9xJnDz_vg==/public/subscribers?groupIds=326095" method="POST" target="_blank" acceptCharset="utf-8" className="stb_form" name="stb_subscribe_form" id="stb_subscribe_form" data-lang="" noValidate>
        <fieldset className="stb_form_set">
          <label htmlFor="stb_email" className="stb_form_set_label"> 이메일 주소를 입력해주세요<span className="stb_asterisk">*</span> </label>
          <input type="text" className="stb_form_set_input" id="stb_email" name="email" required />
          <div className="stb_form_msg_error" id="stb_email_error"></div>
        </fieldset>
        <div className="stb_form_policy">
          <label>
            <input type="checkbox" id="stb_policy" value="stb_policy_true" />
            <span>(필수)</span>
            <button id="stb_form_modal_open" data-modal="stb_form_policy_modal" className="stb_form_modal_open_btn" type="button">개인정보 수집 및 이용</button>에 동의합니다.
          </label>
          <div className="stb_form_msg_error" id="stb_policy_error"></div>
          <div className="stb_form_modal stb_form_policy_text blind" id="stb_form_policy_modal">
            <div className="stb_form_modal_body">
              <h1 className="stb_form_modal_title"> 개인정보 수집 및 이용 </h1>
              <div className="stb_form_modal_text">뉴스레터 발송을 위한 최소한의 개인정보를 수집하고 이용합니다. 수집된 정보는 발송 외 다른 목적으로 이용되지 않으며, 서비스가 종료되거나 구독을 해지할 경우 즉시 파기됩니다.</div>
              <div className="stb_form_modal_btn">
                <button id="stb_form_modal_close" className="stb_form_modal_close_btn" data-modal="stb_form_policy_modal" type="button">닫기</button>
              </div>
            </div>
            <div className="stb_form_modal_bg" id="stb_form_modal_bg"></div>
          </div>
        </div>
        <div className="stb_form_result" id="stb_form_result"> </div>
        <fieldset className="stb_form_set_submit">
          <button type="submit" className="stb_form_submit_button" id="stb_form_submit_button" >구독하기</button>
        </fieldset>
      </form>
    </div>
  );
};

export default SubscriptionForm;
