import React from 'react';
import ContactForm from './ContactForm';
import './ContactModal.css';

function ContactModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className='modal-background' >
      <div className='modal-container' >
        <button className='modal-close-btn' onClick={onClose} >X</button>
        <ContactForm />
      </div>
    </div>
  );
}

export default ContactModal;


