import React, { useState, useEffect } from 'react';
import './TermsOfCondition.css';

export default function TermsOfService() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1140);
    const [selectedVersion, setSelectedVersion] = useState('current');

    const versions = [
        { value: 'current', label: '2024년 8월 1일' },
        { value: 'previous', label: '2022년 8월 1일' },
        // 추후 더 많은 버전을 여기에 추가할 수 있습니다.
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1140);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderSection = (title, content) => {
        return (
            <div className="section">
                <h2 className={`section-title ${isMobile ? 'mobile' : ''}`}>{title}</h2>
                <div className={`section-content ${isMobile ? 'mobile' : ''}`}>
                    {content}
                </div>
            </div>
        );
    };

    const currentVersion = (
        <>
            {renderSection('제1조 (목적)',
                <p>이 약관은 주식회사 프랙탈에프엔(이하 "회사"라 합니다)이 제공하는 리틀버핏 및 리틀버핏 관련 제반 서비스의 이용에 대해 회사와 회원의 권리, 의무 및 책임 사항, 기타 필요한
                    사항을 규정함을 목적으로 합니다.</p>
            )}
            {renderSection('제2조 (용어의 정의)',
                <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                    <br />
                    <br />1. “서비스”란 단말기(PC, 휴대형 단말기 등의 각종 유무선 장치를 포함)에 상관없이, 회원이 이용할 수 있는 리틀버핏 및 리틀버핏 관련 제반 서비스를 의미합니다.
                    <br />2. “회원”이란 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 고객을 말합니다.
                    <br />3. “아이디(ID 또는 계정)”란 회원이 본 약관 및 개인정보처리방침에 동의한 후 회사가 회원을 식별하고 서비스를 제공하기 위해 회원에게 부여한 문자 또는 숫자의 조합을
                    말합니다.
                    <br />4. "닉네임"이란 서비스 이용을 위해, 본 약관 및 개인정보처리방침에 동의한 회원이 정하고 회사가 수집하는 회원의 고유한 이름(문자와 숫자의 조합)을 말합니다.
                    <br />5. "게시물"이란 회원이 서비스에 게시한 부호·문자·음성·음향·화상·동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
                    <br />6. "콘텐츠"란 회사가 회원에게 제공하는 부호, 문자, 도형, 색채, 음성, 음향, 이미지, 영상 등(이들의 복합체를 포함한다.) 온라인 디지털 형태의 정보나 자료를
                    말합니다.
                    <br />7. "중요 제휴사"란 "회사"와 제휴 계약 등을 체결하여 "서비스"의 중요한 일부 기능(로그인 기능 및 연동 증권사를 포함하며, 이에 한정하지 않습니다)을 제공하는
                    회사를 말합니다.
                </p>
            )}
            {renderSection('제3조 (약관의 게시와 개정)',
                <p>
                    ① 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시합니다.
                    <br />② 회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                    <br />③ 회사가 이 약관을 개정할 경우에는 개정내용과 적용 일자를 명시하여 서비스에서 적용 일자 7일 전부터 적용 일자 전날까지 공지합니다. 다만, 회원에게 불리한 변경의 경우에는 적용 일자 30일 전부터 공지합니다.
                    <br />④ 회사가 전항에 따라 공지하면서 회원에게 적용 일자 전날까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정 약관에 동의한 것으로 봅니다.
                    <br />⑤ 회원은 개정 약관에 동의하지 않는 경우 적용 일자 전날까지 회사에 거부 의사를 표시하고 서비스 이용계약을 해지할 수 있습니다.
                </p>)}
            {renderSection('제4조 (약관의 해석)', <p>
                본 약관의 해석에 관하여는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자 보호지침』 등 관련 법령 및 상관례에 따릅니다.
            </p>)}
            {renderSection('제5조 (이용계약 체결 등)', <p>
                ① 이용계약은 회원이 되고자 하는 자(이하 "가입 신청자"라 합니다)가 회사가 제공하는 약관의 내용에 대하여 동의를 한 다음 회사가 정한 절차에 따라 가입신청을 완료하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                <br />
                <br />1. 가입신청자는 컴퓨터, 휴대전화 또는 태블릿 등 리틀버핏 앱을 구동할 수 있는 전자적 장치를 보유하여야 합니다.
                <br />2. 가입신청자는 반드시 신청자 본인의 명의로 가입 신청하여야 하며, 본인확인기관 등을 통하여 실시하는 본인확인을 통과하여야 합니다.
                <br />
                <br />② 회사는 다음 각 호에 해당하는 가입신청에 대하여는 승낙을 하지 않거나, 승낙 이후라도 이용의 제한 또는 이용계약의 해지 등의 조치를 취할 수 있습니다.
                <br />
                <br />1. 가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
                <br />2. 실명이 아닌 명의 또는 타인의 명의를 이용한 경우
                <br />3. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우
                <br />4. 가입 신청자의 귀책 사유로 인하여 승인할 수 없거나 기타 이 약관에서 규정한 제반 사항을 위반하며 신청하는 경우
                <br />5. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우
                <br />6. 관련 법령에 어긋나거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
                <br />7. 가입 신청자가 비거주 외국인이거나 만 19세 미만인 경우
                <br />
                <br />③ 이용계약의 성립 시기는 회사가 계정 생성 완료를 신청절차 상에서 표시하거나 기타 방법을 통해 통지가 가입 신청자에게 도달한 시점으로 합니다.

                <br />④ 회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.
            </p>)}
            {renderSection('제6조 (회원 정보의 변경)', <p>
                ① 회원은 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디는 수정할 수 없습니다.

                <br />② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다.

                <br />③ 회원이 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.
            </p>)}
            {renderSection('제7조 (회원 정보의 관리)', <p>
                ① 회사는 회원의 닉네임이 개인정보 유출 우려가 있거나, 회원 본인이 아닌 타인으로 오해할 소지가 있거나, 반사회적 또는 미풍양속에 어긋나거나, 제삼자의 권리를 침해하거나, 회사 및 회사의 운영자로 오인할 우려가 있는 경우, 해당 닉네임의 이용을 제한할 수 있습니다. 또한 회사는 이 외의 경우라도 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우 해당 닉네임 사용을 제한할 수 있습니다.

                <br />② 회사는 회원이 등록한 계정과 비밀번호 등이 회사에 등록된 것과 일치할 경우에는 별도의 확인절차 없이 이용자를 회원으로 간주합니다.

                <br />③ 회원은 본인의 허가를 받지 않은 제삼자가 회원의 계정에 무단으로 접근하지 않도록, 비밀번호를 포함하여 계정 접근을 위해 필요한 일체의 정보를 안전하게 보관할 책임이 있습니다.

                <br />④ 회원은 계정 접근정보를 분실했거나, 도용 당했거나, 제삼자에게 공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 합니다. 회사는 즉시 계정 이용중단 등의 조치를 할 수 있습니다.

                <br />⑤ 회원의 계정 비밀번호 등 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 책임을 부담하지 아니합니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
            </p>)}
            {renderSection('제8조 (개인정보의 보호)', <p>
                회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외의 외부로 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지 않습니다.
            </p>)}
            {renderSection('제9조 (회사의 의무)', <p>
                ① 회사는 관련 법령과 이 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.

                <br />② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.

                <br />③ 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 서비스 내 게시판, 전자우편 등을 통하여 회원에게 처리 과정 및 결과를 전달할 수 있습니다.
            </p>)}
            {renderSection('제10조 (회원의 의무)', <p>
                ① 회사는 서비스 이용신청이 다음 각 호에 해당하는 것으로 판단되거나 서비스 이용신청 이후의 이용 상황이 다음 각 호의 하나로 판단되는 경우에는 이용 승낙을 하지 아니하거나 또는 이용을 제한하고 탈퇴시킬 수 있습니다.
                <br />
                <br />1. 타인의 정보 도용
                <br />2. 회사가 게시한 정보의 변경
                <br />3. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
                <br />4. 회사와 기타 제삼자의 저작권 등 지적 재산권에 대한 침해
                <br />5. 회사 및 기타 제삼자의 명예를 손상하거나 업무를 방해하는 행위
                <br />6. 외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
                <br />7. 회사의 사전 동의 없이 영리를 목적으로 서비스를 사용하는 행위
                <br />8. 회사의 사전 승낙 없이 자동화된 수단, 기타 부정한 방법을 통하여 서비스에 접속하는 행위, 노출횟수 및 클릭횟수를 부정하게 생성하거나 증가시키는 행위, 서비스 이용 신청을 하는 행위, 회사의 서버에 부하를 일으키는 행위
                <br />9. 다른 회원의 개인정보 및 계정정보를 수집하는 행위
                <br />10. 특정 금융거래정보의 보고 및 이용등에 관한 법률 등 관련법령 상 “서비스”의 중지가 필요하다고 판단되는 경우
                <br />11. 기타 불법적이거나 부당한 행위
                <br />
                <br />② 회원은 관계 법령, 이 약관, 이용안내 및 서비스와 관련하여 회사가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.

                <br />③ 제1항에 해당하는 행위를 한 회원은 이로 인해 회사 또는 다른 회원에게 발생한 손해를 배상할 책임이 있습니다.

                <br />④ 제1항의 회사 조치에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있고, 이의가 정당하다고 인정하는 경우 회사는 해당 서비스의 이용을 재개할 수 있습니다.
            </p>)}
            {renderSection('제11조 (게시물의 저작권)', <p>
                ① 회원이 서비스 내에 게시한 게시물의 저작권은 저작권법에 의하여 보호를 받으며, 적법한 절차와 방법으로 회사에 다음과 같이 사용할 수 있는 영구적인 라이선스를 제공합니다. 상기 라이선스의 사용 및 허용 범위는 아래와 같습니다.
                <br />
                <br />1. 서비스의 운영, 향상, 개선, 신규 서비스 개발, 프로모션 등을 위하여 게시물을 사용, 편집, 저장, 복제, 수정, 공개, 전송, 공개적 실연, 공개적인 게시, 배포할 수 있는 권리
                <br />2. 게시물의 이차적 저작물 제작 및 배포할 수 있는 권리
                <br />
                <br />② 서비스 홍보를 위한 목적으로 미디어, 통신사 등이 게시물의 내용을 보도, 방영하게 할 수 있는 권리회사가 전항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻을 수 있습니다.

                <br />③ 회원이 이용계약을 해지하거나 제20조에 의해 이용계약이 해지되는 경우에도 서비스의 운영 향상, 개선, 홍보 등 제1항에 규정된 목적 범위 내에서 본 라이선스는 존속됩니다.
            </p>)}
            {renderSection('제12조 (게시물의 이용권)', <p>
                ① 회원의 게시물을 임의로 무단 사용하여 발생하는 손실이나 기타의 문제는 전적으로 회원 개인의 판단에 따른 책임이며, 회사는 이에 대하여 책임지지 않습니다.

                <br />② 회원은 타인의 초상권, 저작권 등 지적 재산권 및 기타 권리를 침해하는 목적으로 게시물을 사용할 수 없으며, 만일 타인의 권리를 침해하는 행위로 인하여 발생하는 결과에 대한 모든 책임은 회원 본인에게 있습니다.

                <br />③ 회원은 게시물을 무단으로 상업적이거나 기타 개인적인 이익을 위한 용도로 사용할 수 없습니다.

                <br />④ 회원이 자신의 관계회사(그룹)를 대표하여 가입한 경우, 본 계약에 의하여 가입자에 적용되는 의무를 해당 관계회사가 준수해야 합니다.

                <br />⑤ 회원이 게시물을 회사의 동의를 받지 않고 사용하여 회사에 피해를 준 경우, 회원은 회사에 법적인 절차에 따른 피해보상 의무가 있습니다.
            </p>)}
            {renderSection('제13조 (게시물등에 관한 관리)', <p>
                ① 회원이 서비스 이용 과정에서 게시한 게시물등이 타인의 사생활을 침해하거나 명예를 훼손하는 등 권리를 침해하는 경우, 그 침해받은 자(이하 “권리자”)는 회사에 해당 게시물등의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 정보통신망법 및 저작권법 등 관련 법령 및 회사의 정책에 따라 조치를 취합니다.

                <br />② 회사는 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 정보통신망법 및 저작권법 등 관련 법령 및 회사의 정책에 위반되는 경우에는 해당 게시물등에 대해 삭제조치 또는 관련 법령 및 회사의 정책에 따른 임시조치를 취할 수 있습니다. 다만, 회사는 서비스에 게시되는 모든 게시물 등을 검토할 의무를 지지 않습니다.

                <br />③ 전항에서 정하는 임시조치의 세부 절차는 관련 법령이 규정하는 범위에서 회사가 별도로 정하는 절차에 따릅니다.
            </p>)}
            {renderSection('제14조 (권리의 귀속)', <p>
                ① 서비스에 대한 저작권 및 지적 재산권은 회사에 귀속됩니다.

                <br />② 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 서비스 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
            </p>)}
            {renderSection('제15조 (서비스의 제공 등)', <p>
                ① 회사는 서비스를 일정 범위로 나누어 범위별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만 이러면 그 내용을 사전에 공지합니다.

                <br />② 회사 또는 중요 제휴사가 금융당국으로부터 서비스의 중단 지시, 권고를 받은 경우 회사는 회원에 대한 별도의 통지 없이 본 서비스를 즉시 중단할 수 있으며, 회사의 고의 또는 과실이 없는 한 이 과정에서 발생할 수 있는 회원의 손해를 보상하지 않습니다.

                <br />③ 회원이 증권사 연동 기능을 사용할 경우, 회사는 연동 증권사의 잔고 정보를 기록하여 회사가 서비스하고 있는 포트폴리오 및 수익률의 형태로 제공합니다. 단, 회사는 해당 정보의 정확성이나 실제 여부를 보증하지 않습니다.

                <br />④ 회사가 제공하는 정보는 단순 정보 제공을 목적으로 하며, 거래 가격의 적정성 보증 또는 투자 권유를 의미하지 않습니다. 회원은 독자적인 판단 하에 거래 희망 종목, 구매 수량을 정하고 거래해야 합니다.

                <br />⑤ 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 합리적인 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 사전에 서비스 초기 화면이나 공지사항 게시판을 통하여 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.

                <br />⑥ 회사는 서비스의 제공에 필요한 경우 점검을 할 수 있으며, 점검시간은 서비스 제공화면에 공지한 바에 따릅니다.
            </p>)}
            {renderSection('제16조 (서비스의 변경)', <p>
                ① 회사는 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상, 기술상 사항 등을 변경할 수 있습니다.

                <br />② 회사는 서비스를 변경할 경우 변경내용과 적용 일자를 명시하여 사전에 공지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.

                <br />③ 회사는 제2항에도 불구하고 다음 각호의 경우에 사전 통지 없이 서비스의 대상 증권에서 특정 종목을 제외할 수 있습니다. 이 경우 회사는 이로 인하여 발생할 수 있는 회원의 손해에 관하여 회사의 고의 또는 과실이 없는 한 책임을 부담하지 않습니다.
                <br />
                <br />1. 관련 법령의 적용 또는 감독기관의 지시 등으로 인해 서비스의 대상 증권으로 할 수 없는 경우
                <br />2. 기타 회사가 특정 종목을 서비스 대상 증권으로 하기 부적절하다고 판단하는 경우
                <br />
                <br />④ 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.

                <br />⑤ 회사는 서비스 제공과 관련하여 관련 법령의 변경, 감독기관의 지시 및 회사의 고의 또는 과실이 없는 회사 정책의 변경 등 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있으며 이에 대해 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
            </p>)}
            {renderSection('제17조 (서비스의 이용)', <p>
                ① 회사는 회원에게 주식 등 투자 자산에 관한 정보들을 제공합니다.

                <br />② 회사는 회원이 등록하거나, 증권사로부터 연동한 자산정보를 활용해 포트폴리오 기능을 제공합니다.

                <br />③ 회원은 투자를 결정하는데 있어 회사가 제공한 정보의 정확성과 관련없이 일체의 책임을 지지 않는 것에 동의합니다.
            </p>)}
            {renderSection('제18조 (서비스 이용 관련 유의사항)', <p>
                ① 회사가 제공하는 투자 자산에 관한 정보는 정보의 특성상 시간상의 지연이 있을 수 있습니다.

                <br />② 서비스에서 제공하는 콘텐츠, 투자 자산에 관한 정보는 서비스 이용을 위한 보조 자료이며, 투자 또는 거래의 권유 및 암시가 아닙니다. 회사는 제공하는 투자 자산에 관한 정보의 정확성을 보증하지 않습니다.

                <br />③ 회사는 영업양도, 분할, 합병 등에 따른 영업의 폐지, 중요 제휴사와의 계약 만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 부득이한 사유가 없는 한 중단 일자 30일 이전까지 중단 일자, 중단 사유, 이용자의 자산 정보 이전 방법 등을 서비스 화면에 게시하거나 기타 방법을 통하여 회원에게 통지합니다.
            </p>)}
            {renderSection('제19조 (서비스 이용제한 등)', <p>
                ①  회원의 서비스 이용 중 다음 각 호의 사유가 발생하는 경우, 회사는 해당 회원의 서비스 이용을 제한 또는 정지하거나 이용계약 해지 등의 조치를 취할 수 있습니다.
                <br />
                <br />1. 비밀번호를 회사가 정한 횟수 이상 연속하여 오류 입력하는 경우
                <br />2. 해킹 및 사기 사고가 발생했거나 발생한 것으로 의심되는 경우
                <br />3. 타인의 명의 등을 도용하여 회사가 제공하는 서비스를 이용하는 행위
                <br />4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우
                <br />5. 회원으로 가입된 이용자가 미성년자 또는 비거주 외국인으로 확인된 경우
                <br />6. 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 회사의 시스템에 접근하는 행위
                <br />7. 회원이 회사의 서비스 운영을 고의로 방해하는 경우
                <br />8. 다른 회원의 정당한 이익을 침해하거나 법령에 위배되는 행위를 한 경우
                <br />9. 본 약관에 위배되는 행위를 한 경우
                <br />10. 본 약관에서 규정한 해지사유가 발생한 경우
                <br />11. 회원이 실제소유자인지 여부가 의심되는 등 고객이 자금세탁행위나 공중협박자금조달행위를 할 우려가 있다고 판단되는 경우
                <br />
                <br />② 회원이 본 조의 금지행위를 하는 경우 서비스 이용을 제한함과 별도로 손해배상의 청구, 사법당국에 대한 고발 등 법적 조치를 취할 수 있으며, 필요한 경우 회원의 임의 해지를 일정 기간 제한할 수 있습니다.

                <br />③ 회사는 제1항 또는 관련 법령에 의하여 서비스 이용의 제한 또는 정지 조치를 취하는 경우, 서비스 화면에 게시하거나 기타 방법을 통하여 회원에게 통지합니다. 다만, 다음 각 호의 사유가 있는 경우 회사는 예외적으로 해당 회원의 서비스 이용을 제한 또는 정지하고 지체없이 사후 통지할 수 있습니다.
                <br />
                <br />1. 회사 또는 다른 회원의 정당한 이익을 긴급히 보호해야 할 필요성이 있는 경우
                <br />2. 천재지변 등 불가항력적 사유가 존재하는 경우
                <br />
                <br />④ 회원은 본 조에 따른 이용제한 등에 대해 회사 고객센터를 통하여 이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
            </p>)}
            {renderSection('제20조 (이용계약 해지)', <p>
                ①  회원은 언제든지 회사에 이용계약 해지의 의사를 통지함으로써 이용계약을 해지할 수 있습니다.

                <br />② 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 2회 이상 반복적으로 같은 위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.
                <br />
                <br />1. 본 약관 제10조에 규정된 회원의 의무를 위반하거나 제19조에 서비스 이용 제한 등 사유에 해당하는 경우
                <br />2. 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성 프로그램의 배포, 접속 권한 초과행위 등과 같이 관련 법령을 위반한 경우
                <br />3. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
                <br />4. 그 밖에 각호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우
                <br />
                <br />③ 전 항의 목적 등을 위하여 회사는 회원 탈퇴 시 관련 법령이 정한 기간동안 동안 해당 회원의 성명, 탈퇴일자, 탈퇴사유 등 필요한 정보를 보관할 수 있으며, 회원이 재가입을 한 경우 회사가 제공하는 혜택이나 경제적 이익 제한 등 해지 전과 동일한 상태에서 서비스 제공을 개시할 수 있습니다.

                <br />④ 본 약관에 따라 이용계약이 해지된 경우 회사는 회원에게 부가적으로 제공한 각종 혜택을 무효화하거나 회수할 수 있습니다.
            </p>)}
            {renderSection('제21조 (책임 제한)', <p>
                ① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
                <br />② 서비스에서 제공하는 데이터 및 분석자료는 단순 정보제공을 목적으로 하는 것이며 투자권유를 목적으로 하지 않습니다. 따라서 서비스 및 회사 이외의 정보제공자가 제공하는 정보에는 오류나 서비스 지연이 있을 수도 있고 이러한 정보의 오류나 서비스의 지연에 대해 회사는 책임 지지 않습니다.
                <br />③ 서비스 및 서비스를 이용하여 얻은 정보에 따른 투자는 손실이 발생할 수 있으며 이에 대한 최종 판단은 회원 본인이 하여야 합니다. 투자 판단에 따른 책임은 전적으로 회원에게 있으며 회사는 그로 인하여 발생한 손해에 대해서 책임을 지지 않습니다.
                <br />④ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성, 적법성 등에 관하여 책임을 지지 않습니다.
                <br />⑤ 다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사는 그 손해에 대하여 책임을 지지 아니합니다.
                <br />
                <br />1. 전시, 사변, 천재지변, 또는 이에 준하는 국가 비상사태 등 부득이한 사유가 있는 경우
                <br />2. 정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로 인한 경우
                <br />3. 전기통신사업법에 의한 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애로 인한 경우
                <br />4. 회사가 관리할 수 없는 외주 시스템의 하자 또는 이용자 측 귀책 사유로 인하여 서비스에 장애가 발생한 경우
                <br />5. 순간적인 홈페이지 접속 증가, 일부 종목의 주문 폭주 등으로 인한 서버의 장애 및 아래 각 호에 의한 오류가 발생한 경우
                <br />6. 시세 전송 지연 및 급변 혹은 중요 제휴사의 처리 오류 등으로 인하여 주문의 미처리 및 미체결 등의 오류가 발생한 경우
                <br />7. 주문 응답 지연, 체결 수신 누락 및 지연으로 인한 주문의 미처리, 미체결 및 주문체결의 처리결과가 상이하게 나타난 경우
                <br />8. 시스템 전체 혹은 부분 장애, 특정 부분의 오류(운영 장비, 프로그램, 시세), 시스템 백업 전환 등으로 서비스 또는 주문이 중지된 경우
            </p>)}
            {renderSection('제22조 (회원에 대한 공고 및 통지)', <p>
                ① 회사는 본 약관에 따라 제공되는 서비스의 운영과 관련한 안내사항을 회사의 홈페이지(https://littlebuffett.kr) 또는 리틀버핏앱 서비스 화면 또는 공지사항에의 게시 중 하나 이상의 방법을 이용하여 공지할 수 있습니다.
                <br />② 회사가 회원에게 개별 통지하는 경우, 본 약관에서 달리 정하지 않는 한 리틀버핏앱 푸시 발송, 회원이 제공한 전자우편주소로의 메일 전송, 회원이 제공한 휴대전화번호로의 문자 메시지 또는 SNS(카카오톡 등)를 보내는 방법 중 하나 이상의 방법을 이용하여 통지할 수 있습니다.
                <br />③ 본 조에 따른 공지 및 통지는, 관련 법령 또는 본 약관에서 특별히 전자문서가 아닌 서면 형태를 요구하는 경우가 아닌 한, 서면 통지로서의 효력을 갖습니다.
            </p>)}
            {renderSection('제23조 (광고성 또는 맞춤형 정보의 제공)', <p>
                ① 회사는 본 서비스의 운영과 관련하여 광고성 정보를 제공하는 서비스를 운영할 수 있습니다.
                <br />② 회사는 광고성 정보 및 회원 맞춤형 정보를 문자 메세지나 푸쉬 메세지 형식으로 제공할 수 있으며, 이는 광고성 정보에 대한 사전 수신 동의가 있는 경우 등 법령상 허용된 경우에 한하여 제공됩니다.
            </p>)}
            {renderSection('제24조 (약관의 해석)', <p>
                ① 회사와 회원 사이에 개별적으로 합의한 사항이 본 약관에 정한 사항과 다를 때에는, 그 합의사항을 본 약관에 우선하여 적용합니다.
                <br />② 회사가 제공하는 서비스에 따라 별도로 부가되는 약관(안내내용을 포함하며, 이하 동일함)은 본 약관과 통일적으로 해석되어야 하며, 서로 다른 내용이 있는 경우 원칙적으로 개별 서비스 약관의 내용이 우선합니다.
                <br />③ 본 약관의 내용 중 관련 법령의 강행규정과 배치되는 부분이 있는 경우, 그 부분에 한하여 본 약관의 해당 규정은 무효로 합니다.
                <br />④ 본 약관에 명시적으로 규정되지 않고, 당사자간에 미리 합의되지 않은 사항의 처리에 관하여는 관련 법령 및 상관습에 따릅니다.
            </p>)}
            {renderSection('제25조 (분쟁처리담당자 및 이의신청)', <p>
                ① 회원은 서비스 이용과 관련하여 회사에 이의가 있는 경우 회사에 이의를 제기할 수 있습니다. 분쟁처리책임자의 연락처는 아래와 같습니다.
                <br />1. 분쟁처리책임자의 연락처
                <br />가. 이메일 info@fractalfn.com
                <br />② 회원은 제1항에 따라 서면(전자문서를 포함합니다) 또는 전자적 장치를 이용하여 분쟁처리를 신청할 수 있으며, 회사는 30일 이내에 이에 대한 조사 또는 처리 결과를 회원에게 안내합니다.
            </p>)}
            {renderSection('제26조 (분쟁의 해결)',
                <p>
                    ① 회사와 회원간 발생한 분쟁에 대하여는 대한민국 법을 준거법으로 합니다.
                    <br />② 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 있는 경우 회사와 회원은 이를 해소하기 위하여 성실히 협의합니다.
                    <br />③ 전항에도 불구하고 회사와 회원의 분쟁이 해결되지 않는 경우, 해당 분쟁의 해결은 민사소송법에 따라 관할을 가지는 법원의 판결에 따르기로 합니다.
                    <br />&lt;부칙&gt;<br />본 약관은 2024년 08월 01일부터 적용됩니다.
                </p>)}

        </>);

    const previousVersion = (
        <>
            {renderSection('제1조 (목적)', <p>이 약관은 주식회사 프랙탈에프엔 (이하 "회사"라 합니다)가 제공하는 리틀버핏 및 리틀버핏 관련 제반 서비스의 이용에 대해 회사와 회원의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>)}
            {renderSection('제2조 (정의)', <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                "서비스"라 함은 단말기(PC, 휴대형 단말기 등)에 상관없이, 회원이 이용할 수 있는 리틀버핏의 투자 정보 제공 서비스 및 이와 관련된 제반 서비스를 의미합니다.
                "회원"이라 함은 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 고객을 말합니다.
                "아이디(ID 또는 계정)"라 함은 회원이 본 약관 및 개인정보처리방침에 동의한 후 회사가 회원을 식별하고 서비스를 제공하기 위해 회원에게 부여한 문자 또는 숫자의 조합을 말합니다.
                "닉네임"이라 함은 서비스 이용을 위해, 본 약관 및 개인정보처리방침에 동의한 회원이 정하고 회사가 수집하는 회원의 고유한 이름(문자와 숫자의 조합)을 말합니다.
                "게시물"이라 함은 회원이 서비스에 게시한 부호·문자·음성·음향·화상·동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
                "콘텐츠"라 함은 회사가 회원에게 제공하는 부호, 문자, 도형, 색채, 음성, 음향, 이미지, 영상 등(이들의 복합체를 포함한다.) 온라인 디지털 형태의 정보나 자료를 말합니다.
                "중요 제휴사"라 함은 "회사"와 제휴 계약 등을 체결하여 "서비스"의 중요한 일부 기능(로그인 기능을 포함하며, 이에 한정하지 않습니다)을 제공하는 회사를 말합니다.
                "유료서비스"라 함은 "회사"가 제공하는 서비스 중 '회원'이 '회사'에 일정 금액을 지불한 경우에 이용할 수 있는 '회사'의 서비스(주식 포트폴리오를 포함하며, 이에 한정하지 않습니다.)를 의미합니다.</p>)}
            {renderSection('제3조 (약관의 게시와 개정)', <p>회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시합니다.
                회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                회사가 이 약관을 개정할 경우에는 개정내용과 적용 일자를 명시하여 서비스에서 적용 일자 7일 전부터 적용 일자 전날까지 공지합니다. 다만, 회원에게 불리한 변경의 경우에는 적용 일자 30일 전부터 공지합니다.
                회사가 전항에 따라 공지하면서 회원에게 적용 일자 전날까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                회원은 개정 약관에 동의하지 않는 경우 적용 일자 전날까지 회사에 거부 의사를 표시하고 서비스 이용계약을 해지할 수 있습니다.</p>)}
            {renderSection('제4조 (약관의 해석)', <p>회사는 이 약관 외에 별도의 운영정책을 둘 수 있습니다. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영정책, 이용안내, 관련 법령에 따릅니다.</p>)}
            {renderSection('제5조 (이용계약 체결)', <p>이용계약은 회원이 되고자 하는 자(이하 "가입 신청자"라 합니다)가 회사가 제공하는 약관의 내용에 대하여 동의를 한 다음 회사가 정한 절차에 따라 가입신청을 완료하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                회사는 가입 신청자의 신청에 대하여 아래 각호에 해당하는 사유가 있는 경우에는 승낙하지 않을 수 있으며, 가입 이후에도 아래 각호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수 있습니다.
                가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
                실명이 아닌 명의 또는 타인의 명의를 이용한 경우
                허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우
                가입 신청자의 귀책 사유로 인하여 승인할 수 없거나 기타 이 약관에서 규정한 제반 사항을 위반하며 신청하는 경우
                부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우
                관련 법령에 어긋나거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
                가입 신청자가 비거주 외국인이거나 만 19세 미만인 경우
                이용계약의 성립 시기는 회사가 계정 생성 완료를 신청 절차상에서 표시하거나 제21조의 방식에 따른 통지가 가입 신청자에게 도달한 시점으로 합니다.
                회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.</p>)}
            {renderSection('제6조 (회원 정보의 변경)', <p>회원은 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디는 수정할 수 없습니다.
                회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다.
                회원이 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.</p>)}
            {renderSection('제7조 (회원 정보의 관리)', <p>회사는 회원의 닉네임이 개인정보 유출 우려가 있거나, 회원 본인이 아닌 타인으로 오해할 소지가 있거나, 반사회적 또는 미풍양속에 어긋나거나, 제삼자의 권리를 침해하거나, 회사 및 회사의 운영자로 오인할 우려가 있는 경우, 해당 닉네임의 이용을 제한할 수 있습니다. 또한 회사는 이 외의 경우라도 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우 해당 닉네임 사용을 제한할 수 있습니다.
                회사는 회원이 등록한 계정과 비밀번호 등이 회사에 등록된 것과 일치할 경우에는 별도의 확인절차 없이 이용자를 회원으로 간주합니다.
                회원은 본인의 허가를 받지 않은 제삼자가 회원의 계정에 무단으로 접근하지 않도록, 비밀번호를 포함하여 계정 접근을 위해 필요한 일체의 정보를 안전하게 보관할 책임이 있습니다.
                회원은 계정 접근정보를 분실했거나, 도용 당했거나, 제삼자에게 공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 합니다. 회사는 즉시 계정 이용중단 등의 조처를 할 수 있습니다.
                회원의 계정 비밀번호 등 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 책임을 부담하지 아니합니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.</p>)}
            {renderSection('제8조 (개인정보의 보호)', <p>회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외의 외부로 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지 않습니다.</p>)}
            {renderSection('제9조 (회사의 의무)', <p>회사는 관련 법령과 이 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.
                회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 서비스 내 게시판, 전자우편 등을 통하여 회원에게 처리 과정 및 결과를 전달할 수 있습니다.</p>)}
            {renderSection('제10조 (회원의 의무)', <p>회원은 아래의 행위를 하여서는 안 됩니다.
                타인의 정보도용
                회사가 게시한 정보의 변경
                회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
                회사와 기타 제삼자의 저작권 등 지적 재산권에 대한 침해
                회사 및 기타 제삼자의 명예를 손상하거나 업무를 방해하는 행위
                외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
                회사의 사전 동의 없이 영리를 목적으로 서비스를 사용하는 행위
                회사의 사전 승낙 없이 자동화된 수단, 기타 부정한 방법을 통하여 서비스에 접속하는 행위, 노출횟수 및 클릭횟수를 부정하게 생성하거나 증가시키는 행위, 서비스 이용 신청을 하는 행위, 회사의 서버에 부하를 일으키는 행위
                다른 회원의 개인정보 및 계정정보를 수집하는 행위
                기타 불법적이거나 부당한 행위
                회원은 관계 법령, 이 약관, 이용안내 및 서비스와 관련하여 회사가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>)}
            {renderSection('제11조 (게시물의 저작권)', <p>회원이 서비스 내에 게시한 게시물의 저작권은 저작권법에 의하여 보호를 받으며, 적법한 절차와 방법으로 회사에 다음과 같이 사용할 수 있는 영구적인 라이선스를 제공합니다. 상기 라이선스의 사용 및 허용 범위는 아래와 같습니다.
                서비스의 운영, 향상, 개선, 신규 서비스 개발, 프로모션 등을 위하여 게시물을 사용, 편집, 저장, 복제, 수정, 공개, 전송, 공개적 실연, 공개적인 게시, 배포할 수 있는 권리
                게시물의 이차적 저작물 제작 및 배포할 수 있는 권리
                서
                비스 홍보를 위한 목적으로 미디어, 통신사 등이 게시물의 내용을 보도, 방영하게 할 수 있는 권리회사가 전항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻을 수 있습니다.
                회원이 이용계약을 해지하거나 제21조에 의해 이용계약이 해지되는 경우에도 서비스의 운영 향상, 개선, 홍보 등 제1항에 규정된 목적 범위 내에서 본 라이선스는 존속됩니다.
            </p>)}
            {renderSection('제12조 (게시물의 이용권)', <p>회원의 게시물을 임의로 무단 사용하여 발생하는 손실이나 기타의 문제는 전적으로 회원 개인의 판단에 따른 책임이며, 회사는 이에 대하여 책임지지 않습니다.
                회원은 타인의 초상권, 저작권 등 지적 재산권 및 기타 권리를 침해하는 목적으로 게시물을 사용할 수 없으며, 만일 타인의 권리를 침해하는 행위로 인하여 발생하는 결과에 대한 모든 책임은 회원 본인에게 있습니다.
                회원은 게시물을 무단으로 상업적이거나 기타 개인적인 이익을 위한 용도로 사용할 수 없습니다.
                회원이 자신의 관계회사(그룹)를 대표하여 가입한 경우, 본 계약에 의하여 가입자에 적용되는 의무를 해당 관계회사가 준수해야 합니다.
                회원이 게시물을 회사의 동의를 받지 않고 사용하여 회사에 피해를 준 경우, 회원은 회사에 법적인 절차에 따른 피해보상 의무가 있습니다.
            </p>)}
            {renderSection('제13조 (게시물의 관리)', <p>회원은 타인의 저작권을 침해하는 내용 혹은 허위 사실을 게시물에 포함하여서는 아니 됩니다. 회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라 조처를 하여야 합니다.
                회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나, 허위 사실로 판단되거나, 기타 회사 정책 및 관련 법령에 위반될 경우에는 관련 법령에 따라 해당 게시물에 대하여 게시거부나 삭제 등의 조처를 할 수 있습니다.
                주식회사 프랙탈에프엔은 이용자들의 건전하고 자유로운 콘텐츠 공유를 위해 리틀버핏를 운영합니다. 게시물에는 사용자가 다른 사용자가 볼 수 있도록 작성된 문자, 그림, 사진, 영상, 링크 등으로 구성된 게시물이 해당됩니다. 다음 내용이 포함된 게시물에 해당하는 경우 예고 없이 제한될 수 있습니다. 이외에 부적절한 게시글로 신고 및 확인되는 경우 안내 없이 게시물이 삭제될 수 있습니다.
                회사의 서비스와 무관한 내용의 게시물
                이용자 혹은 타인의 개인정보가 포함된 게시물
                욕설, 비속어에 내용이 포함되어 이용자들의 혐오감, 불쾌감을 유발하는 게시물
                타인에게 성적 수치심을 유발하거나 성별, 인종, 소수계층 등을 비하하고 모욕하는 게시물
                특정 종교나 정치 성향, 사상, 지역 등과 관련된 내용을 언급하여 갈등을 조성하는 게시물
                분란 조장, 분쟁 유도가 될 수 있는 게시물
                재산상의 이득을 취할 목적(도박, 돈 벌기 사이트, 윤락, 경품 지급, 상품 광고, 사이트 홍보 등)의 게시물
                반복적인 도배글, 광고 게시물
                이용자 간의 일대일 투자자문이나 개인투자자의 투자금을 위탁받는 행위
                원금 보장, 손실 보장, 수익보장, 고수익 등 이용자를 현혹시키는 행위
                관계 법령에 따라 투자자문업자, 유사투자자문에게 금지되는 행위
                이용자의 금전적인 피해가 유발되는 게시글
                이용자 간의 분쟁이 발생하여 다른 이용자들의 불쾌감을 유발하는 게시물
                악성코드 배포, 시스템 장애를 유도하는 게시물
                서비스 운영자 및 관계자를 사칭하는 게시물
                회사 및 이용자의 허위사실이나 악의적인 내용을 유포하는 게시물</p>)}
            {renderSection('제14조 (권리의 귀속)', <p>서비스에 대한 저작권 및 지적 재산권은 회사에 귀속됩니다.
                회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 서비스 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
            </p>)}
            {renderSection('제15조 (서비스의 제공 등)', <p>회사는 서비스를 일정 범위로 나누어 범위별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만 이러면 그 내용을 사전에 공지합니다.
                회사 또는 연동 증권사가 금융당국으로부터 서비스의 중단 지시, 권고를 받은 경우 회사는 회원에 대한 별도의 통지 없이 본 서비스를 즉시 중단할 수 있으며, 회사의 고의 또는 과실이 없는 한 이 과정에서 발생할 수 있는 회원의 손해를 배상하지 않습니다.
                회원이 증권사 연동 기능을 사용할 경우, 회사는 연동 증권사의 잔고 정보를 기록하여 회사가 서비스하고 있는 포트폴리오 및 수익률의 형태로 제공합니다. 단, 회사는 해당 정보의 정확성이나 실제 여부를 보증하지 않습니다.
                회사가 제공하는 정보는 단순 정보 제공을 목적으로 하며, 거래 가격의 적정성 보증 또는 투자 권유를 의미하지 않습니다. 회원은 독자적인 판단 하에 거래 희망 종목, 구매 수량을 정하고 거래해야 합니다.
                회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 합리적인 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 사전에 서비스 초기 화면이나 공지사항 게시판을 통하여 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                회사는 서비스의 제공에 필요한 경우 점검을 할 수 있으며, 점검시간은 서비스제공화면에 공지한 바에 따릅니다.</p>)}
            {renderSection('제16조 (서비스의 변경)', <p>회사는 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상, 기술상 사항 등을 변경할 수 있습니다.
                회사는 서비스를 변경할 경우 변경내용과 적용 일자를 명시하여 사전에 공지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.
                회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.</p>)}
            {renderSection('제17조 (서비스의 이용)', <p>회사는 회원에게 주식을 비롯한 다양한 투자 자산에 관한 정보들을 제공합니다.
                회사는 회원이 등록 또는 증권사로부터 연동한 자기 자산정보를 활용해 포트폴리오 기능을 제공합니다.
                회사는 2항의 포트폴리오를 공유하는 기능을 제공합니다.
                회원은 투자를 결정하는데 있어 회사가 제공한 정보의 정합성과 관련없이 일체의 책임을 지지 않는 것에 동의합니다.</p>)}
            {renderSection('제18조 (서비스 이용 관련 유의사항)', <p>회사가 제공하는 투자 자산에 관한 정보는 정보의 특성상 시간상의 지연이 있을 수 있습니다.
                서비스에서 제공하는 콘텐츠, 투자 자산에 관한 정보는 서비스 이용을 위한 보조 자료이며, 투자 또는 거래의 권유 및 암시가 아닙니다. 회사는 제공하는 투자 자산에 관한 정보의 정확성을 보증하지 않습니다.
                회사는 영업양도, 분할, 합병 등에 따른 영업의 폐지, 중요 제휴사와의 계약 만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 다른 어쩔 수 없는 사유가 없는 한 중단 일자 30일 이전까지 중단 일자, 중단 사유, 이용자의 자산 정보 이전 방법 등을 제21조 제1항의 방법으로 회원에게 통지합니다.</p>)}
            {renderSection('제 19조 (이용제한 등)', <p>회사는 다음 각호에 해당하면 회원의 서비스 로그인을 제한할 수 있습니다.
                비밀번호 연속 오류의 경우
                해킹 및 사기 사고가 발생한 경우
                명의도용으로 의심되는 경우
                국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우
                회원으로 가입된 이용자가 미성년자 또는 비거주 외국인으로 확인된 경우
                기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우
                회사는 다음 각호에 해당하면 회원의 입금 및 출금 이용을 보류하거나 제한할 수 있습니다.
                회원으로 가입된 이용자가 미성년자 또는 비거주 외국인으로 확인된 경우
                해킹 및 사기 사고가 발생하였거나 발생한 것으로 의심되는 경우
                명의도용으로 의심되는 경우
                국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우
                기타 각호에 준하는 사유에 해당하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우
                보이스피싱, 자금세탁 등 범죄에 악용될 가능성이 있는 계정(소액, 장기 미사용 계정 등)은 운영정책에 따라 보안등급이 조정될 수 있습니다.
                본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 운영정책, 이용안내 등에서 회사가 정하는 바에 의합니다.
                본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 제21조에 따른 방법으로 회원에게 통지합니다.
                회원은 본 조에 따른 이용제한 등에 대해 고객센터를 통하여 이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.</p>)}
            {renderSection('제20조 (이용계약 해지)', <p>회원은 언제든지 서비스 내 정보 관리 메뉴 또는 고객센터 등을 통하여 이용계약 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
                회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다.
                시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 2회 이상 반복적으로 같은 위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.
                1) 본 약관 제10조에 규정된 회원의 의무를 위반하거나 제19조에 규정된 이용제한 사유에 해당하는 경우
                2) 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성 프로그램의 배포, 접속 권한 초과행위 등과 같이 관련 법령을 위반한 경우
                3) 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
                4) 그 밖에 각호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우
                전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸하며, 회사는 이에 대해 별도로 보상하지 않습니다.
                조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 제21조에 따른 방법으로 회원에게 통지합니다.
                이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 회원의 모든 정보가 삭제됩니다.
                전항에도 불구하고 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청 접수 및 처리 등을 위하여 일정 기간 회원의 정보를 보관할 수 있으며, 해당 기간이 지난 후에 회원의 정보를 삭제합니다.</p>)}
            {renderSection('제21조(유료서비스 등)', <p>리틀버핏가 회원에게 제공하는 서비스는 기본적으로 무료입니다. 단, 리틀버핏가 유료로 책정한 서비스나 제3자와의 업무제휴를 통해 제공하는 별도의 유료정보를 이용하기 위해서는 회원이 해당 정보에 명시된 요금을 먼저 회사가 정한 방법으로 지불하여야 하며 이는 회원의 동의를 전제로 합니다.
                이용요금은 리틀버핏 회원에게 제공되는 서비스 중 유료 서비스를 이용하기 위해 지불하는 요금입니다. 이를 위해 리틀버핏는 유료서비스 이용과 관련하여 다음 각 호의 사항을 해당 유료서비스의 소개화면에 회원이 알기 쉽게 표시합니다. 변경 시에는 변경 7일 전에 고지합니다.
                1) "유료서비스"의 명칭 또는 제호 2) "유료서비스" 제작자의 이름(법인인 경우에는 법인의 명칭) 3) "유료서비스"의 내용, 이용방법, 이용료 기타 이용조건
                리틀버핏는 결제의 이행을 위하여 반드시 필요한 회원의 개인정보를 추가적으로 요구할 수 있으며, 회원은 리틀버핏가 요구하는 개인정보를 정확하게 제공하여야 합니다. 리틀버핏는 회원이 허위로 또는 부정확하게 제공한 개인정보로 인하여 회원에게 발생하는 손해에 대하여 리틀버핏의 고의⋅과실이 없는 한 회원의 손해를 배상할 책임을 부담하지 않습니다.
                유료 서비스를 이용한 회원이 본 약관에서 정한 사유가 발생하여 회원가입을 취소하고 환불을 요구하거나, 리틀버핏의 귀책사유로 서비스를 지속할 수 없을 경우 리틀버핏는 잔여 이용요금을 이용자가 지정하는 구좌로 환불 합니다.</p>)}
            {renderSection('제22조 (이용계약의 성립)', <p>유료서비스를 이용하기 위해서는 회원이 유료서비스 이용에 동의한 후 리틀버핏가 별도로 정하는 이용요금을 납부함으로써 이용계약이 성립됩니다. 서비스 이용자와 이용요금 납입자가 다를 경우 리틀버핏는 이를 확인하기 위하여 제 증명을 요구하고 가입여부를 승인할 수 있습니다.</p>)}
            {renderSection('제23조 (이용자보호)', <p>리틀버핏는 다음 각 호에서 정한 거래관련 정보 관리 절차를 갖고 있습니다.
                리틀버핏가 제공하는 서비스의 명칭, 종류, 내용 및 가격 등을 표시합니다.
                리틀버핏에서 구입하신 서비스는 리틀버핏 고객지원센터로 문의 주시면 안내 받으실 수 있습니다.
                소비자 피해 보상 및 불만이 있을 경우, 고객지원센터를 통해 처리 받으실 수 있습니다.
                회원은 다음과 같은 절차를 통하여 리틀버핏의 서비스를 구매하고, 구매 사항을 교환 및 환불 받으실 수 있습니다.
                구매를 희망하는 리틀버핏 서비스에 대한 이용요금 지급은 카드결제로 가능합니다.
                이미 서비스를 이용한 회원에게는 환불을 하지 않는 것을 원칙으로 하나, 리틀버핏의 시스템상의 오류나 에러로 인한 환불 요청에는 전액 환불 처리합니다.
                불가피한 사유로 서비스를 제공할 수 없을 때, 3일 이내 대금 환불 조치를 취하고 있습니다.
                리틀버핏 고객보호규정에서 규정한 인재 DB 사용제한 업체, 혹은 정당한 사유로 서비스 이용을 제한하는 기업, 중요한 사실을 조작 및 누락하여 서비스를 이용하는 경우, 명의를 도용하여 이용하는 경우, 기타 법규 및 공공정서에 어긋나는 행위를 통해서 서비스를 이용하다 적발되는 경우는 거래취소 및 환불처리가 불가능합니다.
                리틀버핏를 통하여 이용자가 피해를 보았을 때는 이용약관 25조에 따른 환불 등의 방법으로 피해를 보상합니다.
                리틀버핏는 해당 담당직원에게 이용자 보호 지침 공지 및 교육을 실시하고 있습니다.
                서비스에 대한 기타 문의는 리틀버핏 고객지원센터로 문의 주시면 친절히 안내해 드립니다.</p>)}
            {renderSection('제24조(유료서비스 이용계약의 해지)', <p>회원이 유료서비스 이용계약을 해지하고자 하는 때에는 언제든지 회원정보관리에서 리틀버핏가 정한 절차에 따라 회원의 ID를 삭제하고 탈퇴할 수 있습니다.
                회원이 이용약관 제10조의 규정을 위반한 경우 리틀버핏는 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임도 물을 수 있습니다.
                유료서비스 이용계약의 해지는 회원의 서비스 해지신청 및 리틀버핏의 승낙에 의해 성립합니다. 리틀버핏가 승낙한 시점에 해지의 효과는 발생하며 환불할 금액이 있는 경우 환불도 이루어지게 됩니다. 환불에 대해서는 제25조의 규정을 따릅니다.</p>)}
            {renderSection('제25조 (서비스 이용요금 환불)', <p>리틀버핏에서의 포트폴리오 및 멤버십 기간권 상품의 환불 정책은 다음과 같습니다.
                환불 불가
                1주일 이용권
                1개월 이용권
                부분 환불
                3개월 이용권
                환불 금액은 환불 접수 발생 시점을 기준으로 환불규정에 따라 산출됩니다.
                결제 시점으로 부터 환불 접수 시점이 1개월 미만일 경우
                환불액 : 3개월 이용료 - 1개월 이용료 = 27,500원 - 9,900원 = 17,600원
                결제 시점으로 부터 환불 접수 시점이 1개월 이상 2개월 미만일 경우
                환불액 : 3개월 이용료 - 1개월 이용료 = 27,500원 – 2 X 9,900원 = 7700원
                결제 시점으로 부터 환불 접수 시점이 2개월 이상일 경우
                환불액 : 0원</p>)}
            {renderSection('제26조 (회원에 대한 통지)', <p>회사가 회원에 대하여 통지를 하는 경우 이 약관에 별도의 규정이 없는 한 회원이 제공한 전자우편주소 등으로 할 수 있습니다.
                회사는 회원 전체에 대하여 통지를 하는 경우 7일 이상 서비스 내 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다.</p>)}
            {renderSection('제27조 (책임 제한)', <p>회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
                다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사가 회원의 손해 발생을 방지하기 위하여 필요한 관리자의 주의를 다하였음을 입증한 때에는 그 손해에 대하여 책임을 지지 아니합니다.
                전시, 사변, 천재지변, 또는 이에 준하는 국가 비상사태 등 어쩔 수 없는 경우
                정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로 인한 경우
                전기통신사업법에 의한 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애로 인한 경우
                회사가 관리할 수 없는 외주 시스템의 하자 또는 이용자 측 귀책 사유로 인하여 서비스에 장애가 발생한 경우
                순간적인 홈페이지 접속 증가, 일부 종목의 주문 폭주 등으로 인한 서버의 장애가 발생한 경우
                본 조 제2항의 필요한 관리자의 주의라는 것은 다음 사항을 포함합니다.
                정보보호 책임자 선임 및 관리
                정보보호 교육
                전산실(회사의 본질적인 업무인 가상자산 거래에 필요하거나 중요 정보를 저장하고 있는 전산장비, 통신 및 보안장비가 설치된 장소), 전산실이 위치한 건물 내 시설, 정보 보호 시스템(정보처리시스템 내 정보를 유출, 위조, 변조, 훼손하거나 정보처리시스템의 정상적인 서비스를 방해하는 행위로부터 정보 등을 보호하기 위한 장비 및 프로그램) 및 정보처리시스템(가상자산 거래를 포함하여 정보기술 부문에 사용되는 하드웨어, 소프트웨어 및 관련 장비)에 대한 보호 대책
                정보보안사고 대응 방안 수립
                정보보호 대책 및 보안사고 대응방안 등에 대한 정기적인 점검
                제28조 (준거법 및 재판관할)
                회사와 회원간 발생한 분쟁에 대하여는 대한민국 법을 준거법으로 합니다.
                회사와 회원간 발생한 분쟁에 관한 소송의 관할법원은 당사자들이 합의하여 결정하는 법원으로 하며 합의가 이루어지지 않을 때에는 민사소송법에 의한 법원으로 합니다.
                <br />
                <br />부칙
                이 약관은 2022년 08월 01일부터 적용됩니다.</p>)}

        </>
    );

    const paidVersion = (
        <>
            {renderSection('제1조 (목적)',
                <p>
                    이 약관은 주식회사 프랙탈에프엔(이하 "회사"라 합니다)이 제공하는 리틀버핏 및 리틀버핏 관련 제반 유료서비스의 이용에 대해 회사와 회원의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                </p>)}
            {renderSection('제2조 (정의)',
                <p>
                    이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                    <br />
                    <br />1. “유료서비스”란 회원이 회사에 이용요금을 지급한 후에 이용할 수 있는 구독 서비스를 의미합니다.

                    <br />2. “결제”란 회원이 유료서비스를 이용하기 위하여 지급수단을 통하여 유료서비스에 대한 이용요금을 회사에 지급하는 것을 의미합니다.

                    <br />3. “구매”란 회원이 결제를 통하여 유료서비스에 대한 이용권한을 취득하는 행위를 의미합니다.

                    <br />4. “앱 마켓”이란 이용자가 모바일콘텐츠 등을 구매 청약하기 전에 모바일콘텐츠 등의 거래를 중개하는 공간을 의미합니다.

                    <br />5. “인앱 결제”란 앱을 구동한 상태에서 앱 내에서 이용자가 해당 앱 관련 아이템, 상품, 콘텐츠 등을 구매하는 결제방식을 의미합니다.
                </p>)}
            {renderSection('제3조 (약관의 해석)',
                <p>
                    본 약관의 해석에 관하여는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자 보호지침』 등 관련 법령 및 상관례에 따릅니다.
                </p>)}
            {renderSection('제4조 (이용계약 체결 등)',
                <p>
                    ① 유료서비스 이용계약은 본 약관에 동의한 회원이 이용을 원하는 유료서비스를 주문 및 이용요금을 결제하고, 회사가 주문∙결제정보를 확인한 후 해당 주문을 승낙함으로써 회원에게 구매 완료가 표시된 시점에 성립합니다.

                    <br />② 회사는 회원의 주문이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.
                    <br />
                    <br />1. 회원의 주문 내용에 허위, 기재누락, 오기가 있는 경우
                    <br />2. 회원이 타인의 명의 또는 개인정보를 이용하여 주문하는 경우
                    <br />3. 미성년자인 회원이 청소년보호법 등 관련법령에 의해서 이용이 금지되는 콘텐츠를 주문하는 경우
                    <br />4. 회원 자격이 제한 또는 상실된 회원이 주문한 경우
                    <br />5. 관할청의 처분 등으로 해당 유료서비스의 제공이 중단된 경우
                    <br />6. 기타 주문을 승낙하는 것 또는 해당 유료서비스를 제공하는 것에 회사의 기술상 현저히 어려움이 있다고 판단되는 경우
                    <br />
                    <br />③ 이용계약의 성립 후 회사가 제2항 각호의 사유를 발견한 경우 회사는 즉시 계약을 해제 또는 해지할 수 있으며, 계약 해제 또는 해지 시 회사는 회원이 유료서비스 이용요금을 결제한 날로부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다. 단, 제2항 각호의 사유가 회원의 책임 있는 사유로 발생한 경우, 회사는 법령이 허용하는 한도 내에서 환급을 거절할 수 있습니다.
                </p>)}
            {renderSection('제5조 (회원의 유료서비스 이용)',
                <p>
                    ① 회원은 유료서비스를 구매하기 전에 반드시 리틀버핏 서비스 내 작성된 유료서비스의 상세내용과 거래의 조건을 정확하게 확인하여야 합니다. 구매하려는 유료서비스의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손해는 회원 본인이 부담합니다.

                    <br /> ② 회원은 본 약관 및 회사가 서비스 화면에서 공지하는 내용을 준수하여야 하고, 본 약관 및 공지 내용을 위반하거나 이행하지 않아 발생하는 모든 손해에 대하여 책임을 집니다.

                    <br /> ③ 회원은 유료서비스 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생하는 제반 문제에 대한 모든 책임을 부담합니다.

                    <br /> ④ 유료서비스는 구독 서비스를 결제한 회원만을 대상으로 제공되는 것으로, 회원이 리틀버핏 서비스를 탈퇴하거나, 기타 사유로 리틀버핏 서비스가 중단 또는 해지되는 경우에는 더 이상 유료서비스(이미 구매한 유료서비스 포함)를 이용할 수 없습니다.

                    <br />⑤ 회사는 회원의 유료서비스 결제 시 해당 결제수단에 대하여 회원에게 정당한 사용권한이 있는지 확인할 수 있고, 이에 대한 확인이 완료될 때까지 해당 유료서비스의 제공을 개시하지 않거나 중지할 수 있습니다.

                    <br /> ⑥ 회사는 회원에게 서비스가 안전하게 제공될 수 있도록 각종 설비와 자료를 관리하고, 서비스가 제공 목적에 맞게 이용되고 있는지 여부를 확인합니다. 만약 회원이 이용 목적을 위반한 것으로 확인되면 회사는 그에 대한 소명을 회원에게 요청할 수 있고, 유료서비스 주문 취소 등 필요한 조치를 할 수 있습니다.

                    <br /> ⑦ 유료서비스에 대한 구매 여부는 전적으로 회원 본인의 판단과 책임으로 결정하여야 합니다. 회사는 회사의 고의 또는 중과실이 없는 한 회원의 구매 결정에 대하여 책임을 부담하지 아니 합니다.

                    <br />⑧ 회사는 본 약관에 따른 결제에 관한 제반업무를 Google Play 결제 및 Apple Store 결제를  이용하여 처리할 수 있습니다.

                    <br /> ⑨ 회원은 각 앱 마켓에서 제공하는 결제 비밀번호 기능 등을 설정하여 부정한 결제가 이루어지지 아니하도록 관리하여야 합니다. 결제 비밀번호 기능 등을 설정하지 아니하여 발생하는 문제에 대해 회사는 책임지지 않습니다.
                </p>)}
            {renderSection('제6조 (유료서비스의 종류)',
                <p>
                    ① 회사가 회원에게 제공하는 유료서비스의 종류는 다음과 같고, 각 상품별로 제공되는 유료서비스의 종류가 다를 수 있습니다.
                    <br />② 이용기간에 따른 분류
                    <br />
                    <br />1. 1주일(5영업일) 상품
                    <br />2. 1개월(20영업일) 상품
                    <br />
                    <br />③ 서비스에서 제공되는 상품은 저작권자 또는 제작자의 요청에 따라 이용 가능 기간 및 가격 등의 정책이 다를 수 있습니다.
                </p>)}
            {renderSection('제7조 (지급방법)',
                <p>
                    ① 회원은 유료서비스의 이용요금에 대한 대금지급을, 회사가 직접 또는 제3자를 통해 제공하는 결제서비스를 통해 다음 각 호 중 회사가 제공하는 방법으로 할 수 있습니다.
                    <br />
                    <br /> 1. 앱 마켓 정책에 따라 모바일 어플리케이션 내에서 이루어지는 인앱 결제
                    <br />2. 기타 회사가 인정한 전자적 지급 방법에 의한 대금 지급 등
                    <br />
                    <br />② 회원이 구매 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.

                    <br />③ 인앱 결제 시 구매대금을 외화로 결제하는 경우, 환율·수수료 등으로 인하여 실제 청구금액이 표시된 가격과 달라질 수 있으며, 회사는 이에 대하여 책임을 부담하지 않습니다.
                </p>)}
            {renderSection('제8조 (회원의 청약철회 및 청약철회의 효과)',
                <p>
                    ① 회사와 유료서비스 이용에 관한 계약을 체결한 회원은 유료서비스에서 제공되는 콘텐츠를 확인한 것으로 간주되어 청약철회 및 대금 환급이 불가능합니다.

                    <br />② 회원이 회사로부터 이벤트 등을 통해 무료로 지급받은 쿠폰이나 이용권을 이용한 경우, 회사가 회원에게 환급하는 금액은 회원이 실제 지급 또는 결제한 부분에 한정됩니다.

                    <br />③ 전항들의 내용에도 불구하고 제7조 제1항 제1호의 결제 수단으로 이루어진 결제 건의 청약철회, 구매 및 환불 등의 정책은 각 앱 마켓 정책에 따릅니다. 즉, Apple 인앱 결제 이용에 관한 사항은 Apple 이용 약관의 정책을 따르며, Google Play 인앱 결제 이용에 관한 사항은 Google 서비스 약관의 정책을 따릅니다. 또한 회원이 청약철회를 할 경우 회사는 앱 마켓 사업자를 통해 구매내역을 확인할 수 있고, 청약철회 지원에 필요한 정보를 앱 마켓 사업자에게 제공할 수 있습니다.
                </p>)}
            {renderSection('제9조 (과오금에 대한 환불)',
                <p>
                    ① 기술적 오류로 인해 표시된 금액과 실제로 결제된 금액이 달라지는 경우, 회사는 회원의 청구에 따라 이용대금의 결제와 동일한 방법으로 표시된 금액을 초과하여 결제된 금액을 환불하고, 표시된 금액에 미달하는 금액은 추가로 청구합니다. 다만, 이용대금의 결제와 동일한 방법으로 환불이 불가능할 때에는 회원에게 사전에 이를 알립니다.

                    <br />② 제1항에도 불구하고 제7조 제1항 제1호의 결제 수단으로 이루어진 결제 건의 환불은 각 앱 마켓 정책에 따릅니다. 따라서 회원의 결제 수단에 요금을 청구하고 환불을 비롯해 결제와 관련된 문제를 관리할 책임은 해당 앱 마켓에 있으며, 환불은 해당 앱 마켓 서비스의 환불 정책에 따라 진행됩니다.
                </p>)}
            {renderSection('제10조 (손해배상)',
                <p>
                    ① 유료서비스 제공과 관련하여 회사의 귀책사유로 회원에게 손해가 발생한 경우 회사는 회원에게 발생한 손해를 배상하여야 합니다.

                    <br />② 회원이 본 약관상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 또는 회원이 유료서비스를 이용함에 있어 회사에 손해를 입힌 경우, 회원은 회사에 그 손해를 배상하여야 합니다.

                    <br />③ 회사는 서비스의 신뢰에 기인한 손실 등, 여타 간접적인 이익의 손실, 법적 손해배상이 인정되지 아니하는 손해에 대하여서는 손해배상책임을 부담하지 않습니다.
                </p>)}
            {renderSection('제11조 (서비스 제공 불가 시 피해구제)',
                <p>
                    ① 회사는 회원이 주문한 유료서비스를 제공하기 어려운 경우 지체없이 그 사유를 회원에게 통지하고, 사전에 해당 유료서비스의 이용요금을 지급받은 경우에는 이용요금을 받은 날로부터 7영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.

                    <br />② 회사의 고의 또는 중과실로 인해 회원에게 유료서비스가 정상적으로 제공되지 않는 경우, 회사는 지체없이 유료서비스의 제공을 정상화합니다. 회사의 고의 또는 중과실로 인해 유료서비스의 제공이 장기간 정상화되지 않는 경우, 그 기간, 사유, 회원의 과실 여부 및 정도 등 제반 사정을 고려하여 정한 금액을 회원에게 환불할 수 있습니다.
                </p>)}
            {renderSection('제12조 (책임의 제한)',
                <p>
                    회사 및 회사의 임직원 그리고 대리인은 『리틀버핏 이용약관』에서 책임의 제한 사유로 정하고 있는 사항에 더하여, 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는 손해에 대하여 책임을 지지 않습니다.
                    <br />
                    <br />1. 천재지변 또는 이에 준하는 불가항력으로 인해 유료서비스를 제공할 수 없는 경우
                    <br />2. 회사의 관리영역이 아닌 통신서비스, 통신설비, 디바이스의 품질, 중지, 중단, 장애, 오류, 하자, 한계, 호환성 문제 등으로 회원의 유료서비스 이용이 곤란하거나 불가능한 경우
                </p>)}
            {renderSection('제13조 (기타)',
                <p>
                    본 약관과 『리틀버핏 이용약관』이 상충하는 경우 본 약관이 우선하여 적용되고, 본 약관에서 정하지 않은 사항은 『리틀버핏 이용약관』을 적용합니다.
                    <br />
                    <br />&lt;부칙&gt;<br />본 약관은 2024년 08월 01일부터 적용됩니다.
                </p>)}
        </>
    );

    const getVersionContent = (version) => {
        switch (version) {
            case 'current':
                return currentVersion;
            case 'previous':
                return previousVersion;
            default:
                return <p>선택된 버전의 내용을 찾을 수 없습니다.</p>;
        }
    };


    return (
        <div className={`terms-of-service ${isMobile ? 'mobile' : ''}`}>
            <h1 className="main-title">리틀버핏 이용약관</h1>

            <div className="version-selector">
                <select
                    value={selectedVersion}
                    onChange={(e) => setSelectedVersion(e.target.value)}
                    className="version-dropdown"
                >
                    {versions.map((version) => (
                        <option key={version.value} value={version.value}>
                            {version.label}
                        </option>
                    ))}
                </select>
            </div>

            {getVersionContent(selectedVersion)}

            {selectedVersion === 'current' ?
                <>
                    <br />
                    <h1 className="main-title">리틀버핏 유료서비스 이용약관</h1>
                    {paidVersion}
                </> : null
            }        </div>
    );
}



