import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './ImageSlider.css';

const ImageSlider = ({ images, afterChange, currentSlide, text }) => {
  const sliderRef = useRef(null); // 슬라이더에 대한 참조 생성

  const settings = {
    dots: true, // 하단 도트 네비게이션 표시
    infinite: true, // 무한 루프 슬라이딩
    speed: 500, // 슬라이드 전환 속도
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
    slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 수
    autoplay: true, // 자동 슬라이드
    autoplaySpeed: 3000, // 자동 슬라이드 인터벌
    arrows: false,
    afterChange: afterChange,
  };

  useEffect(() => {
    // currentSlide가 변경될 때 슬라이더를 해당 슬라이드로 이동
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentSlide);
    }
  }, [currentSlide]); // currentSlide가 변경될 때만 이 코드를 실행

  return (
    <Slider ref={sliderRef} {...settings}>
      {images.map((image, idx) => (
        <div key={idx}>

          <div className='stage-container'>
            <div className='stage'>{idx + 1}</div>
            <div className='stage-text-container'>
              <div className='stage-text'>{text[idx][0]}</div>
              <div className='stage-text'>{text[idx][1]}</div>
            </div>
          </div>
          <img className='slide-image' src={image} alt={`Slide ${idx}`} />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
