import React from 'react';
import './IosLaunchModal.css';

const IosLaunchModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content">
        <button onClick={onClose}>X</button>
        <div className='modal-title'>iOS 앱 출시 예정</div>
        <div className='modal-text'>iOS 앱은 3월에 런칭됩니다.</div>
      </div>
    </div>
  );
};

export default IosLaunchModal;
