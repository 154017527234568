import './App.css';
import { ScrollProvider } from './ScrollContext';
import { ModalProvider } from './ModalContext';
import Layout from './Layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TermsOfCondition from './TermsOfCondition';
import InformationHandling from './InformationHandling';
import EventPage from './EventPage';
import AwardsPage from './AwardsPage';
import InterviewPage from './InterviewPage';


function App() {

  return (
    <>
      <Router>
        <ScrollProvider>
          <ModalProvider>
            <Routes>
              <Route path="/" element={<Layout />} />
              <Route path="/terms-of-condition" element={<TermsOfCondition />} />
              <Route path="/information-handling" element={<InformationHandling />} />
              <Route path="/event-page" element={<EventPage />} />
              <Route path="/awards-page" element={<AwardsPage />} />
              <Route path="/interview-page" element={<InterviewPage />} />
            </Routes>
          </ModalProvider>
        </ScrollProvider>
      </Router>
    </>
  );
}

export default App;
