import React from 'react';
import { useModal } from './ModalContext';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import ContactModal from './ContactModal';
import EventModal from './EventModal';

function Layout() {
  const { isModalOpen, hideModal } = useModal();

  return (
    <>
      <Header />
      <Main />
      <Footer />
      <ContactModal isOpen={isModalOpen} onClose={hideModal} />
    </>
  );
}

export default Layout;
